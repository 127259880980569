import React, { createContext, ReactNode, useState } from "react";
import { GlobalContextType, IBreakPoint, IEnvironment, IFooter, IHeader } from "../@types/global";

interface Props {
  children: ReactNode
}

const GlobalContext = createContext<GlobalContextType | null>(null);

const GlobalProvider = ({ children }: Props) => {

  const [environment, setEnvironment] = useState<IEnvironment | any>(null);
  const [breakpoint, setBreakpoint] = useState<IBreakPoint | any>(null);
  const [header, setHeader] = useState<IHeader | any>(null);
  const [footer, setFooter] = useState<IFooter | any>(null);

  return (
    <GlobalContext.Provider value={{
        publicVariable: environment,
        setPublicVariable: setEnvironment,
        breakpoint,
        setBreakpoint,
        header,
        setHeader,
        footer,
        setFooter,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
